<template>
  <div>
    <div>
      <v-carousel
        style="position: absolute; z-index: 1"
        hide-delimiters
        cycle
        height="100vh"
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(item, index) in carouselItems1" :key="index">
          <v-img
            :src="item.image"
            alt="Banner Image"
            class="banner-image"
          ></v-img>
        </v-carousel-item>
      </v-carousel>

      <div class="overlay" v-if="true">
        <v-container class="card-container">
          <v-row justify="center">
            <v-col align="center" cols="12">
              <span class="white--text text-h3 font-weight-bold">
                <v-flex class="mt-5" v-if="true">
                  <v-img
                    lazy-src="../../assets/logo.png"
                    max-height="300"
                    max-width="300"
                    contain
                    src="../../assets/logo.png"
                  ></v-img>
                </v-flex>

                <v-flex class="mt-n5">
                  <div class="title text-center">
                    <h2>
                      <div class="waviy mb-4">
                        <span style="--i: 1">S</span>
                        <span style="--i: 2">I</span>
                        <span style="--i: 1">L</span>
                        <span style="--i: 1">A</span>
                        <span style="--i: 2">Y</span>
                        <span style="--i: 1">A</span>
                        <span style="--i: 2">K</span>
                      </div>
                    </h2>
                    <v-flex class="mt-n2">
                      <div class="my-title1">BKPSDM</div>
                    </v-flex>
                    <v-flex class="mt-n2">
                      <div class="my-title2">Kota Palangkaraya</div>
                    </v-flex>
                  </div>
                </v-flex>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="overlay" v-if="false">
        <v-container class="card-container">
          <v-row justify="center">
            <v-col align="center" cols="12">
              <span class="white--text text-h3 font-weight-bold">
                ETALASE APLIKASI
              </span>
            </v-col>
          </v-row>
          <v-slide-group
            v-model="model"
            class="pa-4 rounded"
            center-active
            show-arrows
            cycle
          >
            <template v-slot:prev="{ prev }">
              <v-btn @click="prev" fab color="white" x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:next="{ next }">
              <v-btn @click="next" fab color="white" x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>

            <v-slide-item
              v-for="item in carouselItems2"
              :key="item.id"
              v-slot="{ toggle }"
            >
              <v-route-link style="text-decoration: none">
                <v-card
                  class="text-center rounded-lg ma-4"
                  max-width="280px"
                  height="320px"
                  elevation="10"
                  @click="toggle"
                >
                  <v-img :src="item.image" width="280px" height="180px"></v-img>
                  <v-divider class="mx-6"></v-divider>
                  <v-card-text class="text-center">
                    <div class="headline black--text mt-5 font-weight-bold">
                      {{ item.title.trim() }}
                    </div>
                  </v-card-text>
                  <div class="px-5 body-2 grey--text">
                    {{ item.description }}
                  </div>

                  <div>
                    <v-img class="ma-5" :src="item.image" v-if="false"></v-img>
                    <div></div>
                  </div>
                </v-card>
              </v-route-link>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>
    </div>
    <div v-if="false" class="py-16">
      <div class="header-title pb-12">
        <span class="text-h6 font-weight-medium">Bidang</span>
        <v-img :src="src2"></v-img>
      </div>
      <div>
        <v-container>
          <v-row class="px-6">
            <v-col
              v-for="(item, index) in carouselBidang"
              class="px-3 py-6"
              :key="index"
              cols="12"
              md="3"
              sm="3"
              xs="3"
            >
              <v-card class="text-center px-8 pt-8" @click="detailBidang(item)">
                <div class="card-image-cover-bidang">
                  <v-img
                    class="card-image-bidang"
                    :src="item.image"
                    width="100%"
                  ></v-img>
                </div>
                <v-card-text> {{ item.title }} </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="py-16" v-if="false">
      <div class="header-title pb-12">
        <span class="text-h6 font-weight-medium">Berita Terbaru</span>
        <v-img :src="src2"></v-img>
      </div>
      <div>
        <v-container>
          <!-- {{ fNewsFiltered }} -->
          <v-layout wrap justify-center class="d-flex mt-2">
            <div v-for="(item, i) in fNewsFiltered" :key="i">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <!-- <router-link style="text-decoration: none" :to="'public-detail-produk/' + linkNeated(item.id.toString())"> -->
                  <v-card
                    width="330"
                    xs12
                    sm4
                    px-3
                    class="ma-3 rounded-lg pb-6"
                    :elevation="hover ? 16 : 3"
                  >
                    <div>
                      <v-hover>
                        <template v-slot:default="{ hover }">
                          <v-img
                            height="250"
                            :src="lookupImageUrl(item)"
                            :class="{ 'hover-image': hover }"
                            class="rounded-lg"
                          ></v-img>
                        </template>
                      </v-hover>
                    </div>
                    <v-card-title></v-card-title>
                    <v-card-text>
                      <div class="text-h5 font-weight-bold">
                        {{ item.title }}
                      </div>
                      <div class="text-caption">
                        {{ computedDateFormattedDatefns(item.dateFrom) }}
                      </div>
                      <div class="text-subtitle-2 mt-6">
                        {{ item.contentMeta }}
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <v-row align="start" class="px-3">
                        <div>{{ item.contentBody }}...</div>
                        <v-btn
                          outlined
                          class="caption font-weight-bold primary--text mt-3"
                          @click="routeToAgenda(item)"
                          >Selengkapnya</v-btn
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <!-- </router-link> -->
                </template>
              </v-hover>
            </div>
          </v-layout>
          <v-row
            class="mt-8"
            justify="center"
            align="center"
            v-if="pageSize <= 5"
          >
            <v-btn @click="growFNews" dark color="amber darken-2"
              >Tampilkan Lebih Banyak
              <v-icon class="ml-2">mdi-chevron-down</v-icon></v-btn
            >
          </v-row>
          <v-row
            class="mt-8"
            justify="center"
            align="center"
            v-if="pageSize >= 5"
          >
            <v-btn @click="unGrowFNews" dark color="amber darken-2"
              >Tampilkan Lebih Sedikit
              <v-icon class="ml-2">mdi-chevron-up</v-icon></v-btn
            >
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-if="false" class="py-16">
      <div class="header-title pb-12">
        <span class="text-h6 font-weight-medium">Video Terbaru</span>
        <v-img :src="src2"></v-img>
      </div>
      <div>
        <v-container>
          <v-row class="px-6">
            <v-col
              v-for="(item, index) in carouselVideo"
              class="px-2 py-8"
              :key="index"
              cols="12"
              md="4"
              sm="6"
              xs="6"
            >
              <v-card
                @mouseover="setOverlayVideo(index, true)"
                @mouseout="setOverlayVideo(index, false)"
                class="card-hover"
              >
                <div class="card-image-cover-video">
                  <v-img
                    class="card-image-video"
                    :src="item.image"
                    width="100%"
                  ></v-img>
                </div>
                <span v-if="overlayVideo === index" class="overlay-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse.
                  <br /><br />
                  <span class="mt-5">tanggal</span>
                </span>
                <v-overlay
                  :absolute="absoluteVideo"
                  :value="overlayVideo === index"
                ></v-overlay>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-8" justify="center" align="center">
            <v-btn dark color="amber darken-2"
              >Tampilkan Lebih Banyak
              <v-icon class="ml-2">mdi-chevron-down</v-icon></v-btn
            >
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

  <script>
import FNewsService from "@/services/apiservices/f-news-service";
import FileService from "@/services/apiservices/file-service";

import FNews from "@/models/f-news";

import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 3,
      pageSizes: [3],
      itemsPerSlide: 3,

      model: null,
      search: "",
      fBeritas: [new FNews(1, "001")],
      itemsFDivision: [],
      absoluteBerita: true,
      overlayBerita: null,
      absoluteVideo: true,
      overlayVideo: null,
      carouselItems1: [
        {
          title: "Card 1",
          description: "This is the content of Card 1",
          image: "./assets/carousel/carousel-1.jpg",
        },
        //   { title: 'Card 6', description: 'This is the content of Card 6', image: './assets/images/gambar3.jpg' },
      ],
      carouselItems2: [
        {
          id: 1,
          title: "SIASN",
          description: "Aplikasi Sistem Informasi Aparatur Sipil Negara",
          image: "./assets/images/SIASN_APS.png",
          // url: "https://sirutilahu.kotawaringinbaratkab.go.id/"
        },
        {
          id: 2,
          title: "SIMPEG",
          description: "Aplikasi Sistem Informasi Manajemen Kepegawaian",
          image: "./assets/images/SIMPEG_APS.png",
          // url: "https://datartlh.perumahan.pu.go.id/"
        },
        {
          id: 3,
          title: "CBT Palangka",
          description: "Aplikasi CBT Kota Palangkaraya",
          image: "./assets/images/CBT_APS.png",
          // url: "https://datartlh.perumahan.pu.go.id/"
        },
      ],
      carouselBidang: [
        {
          title: "Nama Bidang 1",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam?",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 2",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam?",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 3",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam?",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 4",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam? ",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 5",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam? ",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 6",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam? ",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 7",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam? ",
          image: "./assets/images/bidang.png",
        },
        {
          title: "Nama Bidang 8",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem minima non nobis. Eos nostrum autem reiciendis at! Aperiam dolorum voluptates eos enim quos quis nam? ",
          image: "./assets/images/bidang.png",
        },
        //   { title: 'Card 6', description: 'This is the content of Card 6', image: './assets/images/gambar3.jpg' },
      ],
      carouselBerita: [
        {
          title: "Bidang 1",
          description: "This is the content of Card 1",
          image: "./assets/images/lapor.png",
        },
        {
          title: "Bidang 2",
          description: "This is the content of Bidang 2",
          image: "./assets/images/smartejakon.png",
        },
        {
          title: "Bidang 3",
          description: "This is the content of Bidang 3",
          image: "./assets/images/gambar3.jpg",
        },
        {
          title: "Bidang 3",
          description: "This is the content of Bidang 3",
          image: "./assets/images/gambar3.jpg",
        },
        //   { title: 'Card 6', description: 'This is the content of Card 6', image: './assets/images/gambar3.jpg' },
      ],
      carouselVideo: [
        {
          title: "Bidang 1",
          description: "This is the content of Card 1",
          image: "./assets/images/gambar1.jpg",
        },
        {
          title: "Bidang 2",
          description: "This is the content of Bidang 2",
          image: "./assets/images/gambar2.jpg",
        },
        {
          title: "Bidang 3",
          description: "This is the content of Bidang 3",
          image: "./assets/images/gambar3.jpg",
        },
        //   { title: 'Card 6', description: 'This is the content of Card 6', image: './assets/images/gambar3.jpg' },
      ],
      itemsPerRow: 3,
      src1: "./assets/images/dayak.png",
      src2: "./assets/images/aksen.png",
      textFieldValue: "",
    };
  },
  computed: {
    slideApps() {
      const slides = [];
      for (let i = 0; i < this.carouselItems2.length; i += this.itemsPerSlide) {
        slides.push(this.carouselItems2.slice(i, i + this.itemsPerSlide));
      }
      return slides;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    fNewsFiltered() {
      let beritasModified = [];
      for (let i = 0; i < this.fBeritas.length; i++) {
        let itemBerita = this.fBeritas[i];
        if (itemBerita.contentBody !== undefined) {
          if (itemBerita.contentBody.length > 100) {
            itemBerita.contentBody = itemBerita.contentBody.substr(0, 99);
          }
        }
        beritasModified.push(itemBerita);
      }

      return beritasModified;

      // return this.fBeritas
    },
    rowItemsArray() {
      const rows = [];

      for (let i = 0; i < this.carouselItems2.length; i += this.itemsPerRow) {
        const rowItems = this.carouselItems2.slice(i, i + this.itemsPerRow);
        rows.push(rowItems);
      }

      return rows;
    },
    itemsPerPage() {
      return this.itemsPerRow;
    },
    rowItemsArray2() {
      const rows = [];
      const totalItems = this.carouselItems2.length;
      // console.log(totalItems);
      const rowCount = Math.ceil(totalItems / this.itemsPerRow);

      for (let i = 0; i < rowCount; i++) {
        const startIndex = i * this.itemsPerRow;
        const endIndex = startIndex + this.itemsPerRow;
        let rowItems = this.carouselItems2.slice(startIndex, endIndex);

        // Add an extra empty placeholder if row contains only 2 items
        if (rowItems.length === 2) {
          rowItems.push(null);
        }

        // Fill the row with empty placeholders if there are fewer items
        if (rowItems.length < this.itemsPerRow) {
          const emptyItemsCount = this.itemsPerRow - rowItems.length;
          for (let j = 0; j < emptyItemsCount; j++) {
            rowItems.push(null);
          }
        }

        rows.push(rowItems);
      }

      return rows;
    },
    itemsPerPage2() {
      return this.itemsPerRow;
    },
  },
  methods: {
    routeToAgenda(item) {
      this.$router.push(
        `/public-news-page/` + this.linkNeated(item.id.toString())
      );
    },
    routeToExternalLink(item) {
      window.location.href = item.url;
    },
    growFNews() {
      this.pageSize = 6;
      this.fetchFNews();
    },
    unGrowFNews() {
      this.pageSize = 3;
      this.fetchFNews();
    },
    fetchFNews() {
      FNewsService.getAllFNewsContainingPublicBerita(
        this.currentPage,
        this.pageSize,
        "created",
        "DESC",
        this.search
      ).then(
        (response) => {
          // console.log(response.data.items)
          const { items, totalPages } = response.data;
          this.fBeritas = items;
          this.totalPaginationPages = totalPages;

          // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
        },
        (error) => {
          console.log(error.response);
        }
      );
    },

    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },

    lookupImageUrl(item) {
      if (item.coverImage === undefined || item.title === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_low(item.coverImage);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/no_image_available.jpeg";
    },

    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMM yyyy") : "";
    },
    dateTimeFormat(value) {
      return value ? format(parseISO(value), "dd-MMM-yyyy hh:mm:ss") : "";
    },
    formattedCurrencyValue(prefix, value) {
      if (!value) {
        return "0";
      }
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString();
    },
    detailBidang(item) {
      this.$store.dispatch("BidangModule/updateCurrentItemBidang", item);
      this.$router.push("/beranda-detail-bidang");
      window.location.href = "/beranda-detail-bidang";
    },
    setOverlayBerita(index, value) {
      this.overlayBerita = value ? index : null;
    },
    setOverlayVideo(index, value) {
      this.overlayVideo = value ? index : null;
    },
    updateItemsPerSlide() {
      // Update itemsPerSlide based on screen width
      if (window.innerWidth < 600) {
        this.$emit("update:itemsPerSlide", 1);
      } else {
        this.$emit("update:itemsPerSlide", 3);
      }
    },
  },
  beforeDestroy() {
    // Clean up the listener
    window.removeEventListener("resize", this.updateItemsPerSlide);
  },
  mounted() {
    // Add a listener for window resize
    window.addEventListener("resize", this.updateItemsPerSlide);
    // Call the method initially
    this.updateItemsPerSlide();
    this.fetchFNews();
    // this.fetchParent()
  },
};
</script>
  
<style scoped>
.card-hover {
  cursor: pointer;
  position: relative;
}

.overlay-text {
  color: #ffffff;
  position: absolute;
  pointer-events: none;
  display: inline-block;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -25%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 99 !important;
  width: 90%;
  /* Adjust the max-width value as needed */
  text-align: left; /* Center the text within the card */
}

.card-hover:hover .overlay-text {
  opacity: 1;
}
.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-container .v-carousel .v-carousel__controls .v-carousel__controls__item {
  width: 10px !important; /* Adjust the width as desired */
  height: 10px !important; /* Adjust the height as desired */
  background-color: #f44336 !important; /* Adjust the background color as desired */
  border-radius: 50% !important; /* Make it circular */
  margin: 0 5px !important; /* Adjust the margin as desired */
}

.card-image-cover-aps .card-image-aps {
  width: 100%; /* Set the width to occupy the entire card */
  height: 200px;
  object-fit: cover;
}
.card-image-cover-bidang {
  box-sizing: border-box !important;
  object-fit: cover;
}
.card-image-cover-bidang .card-image-bidang {
  width: 100%; /* Set the width to occupy the entire card */
  height: 200px;
  object-fit: cover;
}
.v-overlay {
  z-index: 0;
}
.card-image-cover-berita {
  position: relative;
  z-index: 1;
  box-sizing: border-box !important;
  object-fit: cover;
}
.card-image-cover-berita .card-image-berita {
  width: 100%; /* Set the width to occupy the entire card */
  height: 250px;
  object-fit: cover;
}
.card-image-cover-video {
  position: relative;
  z-index: 1;
  box-sizing: border-box !important;
  object-fit: cover;
}
.card-image-cover-video .card-image-video {
  width: 100%; /* Set the width to occupy the entire card */
  height: 300px;
  object-fit: cover;
}

.v-card__text {
  height: 25%;
  overflow: hidden;
}
.banner-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.overlay {
  padding: 10% 0;
  position: relative;
  z-index: 99;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0) 0%,
    rgba(10, 10, 10, 0.17) 18.99%,
    rgba(10, 10, 10, 0.21) 20.34%,
    rgba(10, 10, 10, 0.35) 30.34%,
    rgba(10, 10, 10, 0.72) 70.41%,
    #0a0a0a 99%
  );
  height: 100vh;
  width: 100%;
}
.card-container {
  position: relative;
  z-index: 99;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* margin-top: -100px; */
  background: transparent;
}

.floating-card {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .card-container {
    margin-top: 0;
  }
}

.my-title1 {
  font-size: 24px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip1 1.5s linear infinite;
  display: inline-block;
  font-size: 24px;
}
@keyframes textclip1 {
  to {
    background-position: 300% center;
  }
}

.my-title2 {
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip2 2s linear infinite;
  display: inline-block;
  font-size: 20px;
}
@keyframes textclip2 {
  to {
    background-position: 200% center;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy {
  position: relative;
  font-weight: bolder;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 60px;
  color: #69da6f;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
</style>