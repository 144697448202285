<template>
  <div>
    <Dashboard v-if="false"></Dashboard>
    <v-container>
      <v-row v-if="false">
        <v-col cols="12" md="6" sm="6">
          <v-img
            height="300px"
            src="../assets/images/silayak-dashboard-1.png"
            contain
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-img
            height="300px"
            src="../assets/images/silayak-dashboard-2.png"
            contain
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <RekapitulasiLayanan></RekapitulasiLayanan>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="subtitle-2">
            BKPSDM Siap melayani anda dengan Innovasi yang cepat
          </p>
          <v-btn color="primary" x-large to="/registrasi-layanan"
            >Menuju ke Layanan</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-row class="mt-10">
            <v-col cols="12" sm="4" md="4"></v-col>
            <v-col cols="12" sm="4" md="4">
              <p>
                Jl. G. Obos XI (Lingkar Dalam) PALANGKA RAYA Kota Palangka Raya,
                Kalimantan Tengah 73111 Telpon (0536) 3239164 – 3242390 Fax.
                (0536) 3242390
              </p>
            </v-col>
            <v-col cols="12" sm="4" md="4"></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12">
          <RegistrasiLayananView></RegistrasiLayananView>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard";
import RekapitulasiLayanan from "@/components/dashboard/RekapitulasiLayanan.vue";
import RegistrasiLayananView from "@/views/admin_registrasi/RegistrasiLayananView.vue";
export default {
  name: "DashboardView",
  components: {
    RekapitulasiLayanan,
    RegistrasiLayananView,
    Dashboard,
  },
};
</script>

<style scoped>
</style>