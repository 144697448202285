<template>
  <v-footer
    style="background-color: #464955"
    class="pa-3 color-gradient-1 d-flex"
    height="auto"
  >
    <div class="ml-4">
      <v-flex class="d-flex">
        <router-link to="/" class="text-decoration-none">
          <v-btn outlined class="rounded-lg" color="white">
            <span class="font-weight-bold orange--text headline">SI</span>
            <span class="font-weight-bold white--text headline">LAYAK</span>
          </v-btn>
        </router-link>
      </v-flex>

      <v-flex text-xs-center white--text xs12 class="my-2">
        <v-btn route to="/login" class="glow-on-hover"> Login Admin </v-btn>
      </v-flex>
      <v-flex class="d-flex">
        <v-flex white--text class="ma-2 font-weight-bold">
          <div>&copy; {{ new Date().getFullYear() }}</div>
          <div class="font-weight-regular" @click="showDialogChartVisitor">
            <span>
              Indonesia:
              <span class="font-weight-bold ml-1">{{
                visitorCounter.indonesia
              }}</span>
            </span>
            <span class="ml-6 font-weight-light">
              Negara Lain:
              <span class="font-weight-regular ml-1">{{
                visitorCounter.negaraLain
              }}</span>
            </span>
          </div>
        </v-flex>
      </v-flex>
    </div>

    <v-spacer></v-spacer>

    <div class="mr-10">
      <v-flex class="d-flex ml-4">
        <div class="mt-6">
          <div class="icons">
            <a
              href="https://instagram.com/disperkim_kobar"
              class="icon icon--instagram"
            >
              <v-icon>mdi-instagram</v-icon>
            </a>
            <a
              href="https://www.facebook.com/Dinas Perkim Kobar"
              class="icon icon--twitter"
            >
              <v-icon>mdi-facebook</v-icon>
            </a>
            <a href="#" class="icon icon-tiktok" v-if="false">
              <v-icon>mdi-music</v-icon>
            </a>
            <a href="#" class="icon icon--instagram">
              <v-icon>mdi-youtube</v-icon>
            </a>
            <a
              href="https://dpmptsp.jatimprov.go.id"
              class="icon icon--github"
              v-if="false"
            >
              <v-icon>mdi-microsoft-internet-explorer</v-icon>
            </a>
          </div>

          <v-flex class="d-flex">
            <v-flex white--text class="ma-2 font-weight-bold">
              <div class="subtitle-2" v-if="true">
                Jl. G. Obos XI (Lingkar Dalam) PALANGKA RAYA
              </div>
              <div class="subtitle-2">
                Kota Palangka Raya, Kalimantan Tengah 73111
              </div>
              <div class="subtitle-2">
                <span>
                  <v-icon small color="white">mdi-phone</v-icon>
                  <span>
                    Telpon (0536) 3239164 – 3242390 Fax. (0536) 3242390
                  </span>
                </span>
              </div>
            </v-flex>
          </v-flex>
        </div>
      </v-flex>

      <ChartVisitorDialog ref="chartVisitorDialog"> </ChartVisitorDialog>
    </div>
  </v-footer>
</template>
<script>
import FVisitorService from "@/services/apiservices/f-visitor-service";
import ChartVisitorDialog from "@/components/public-chart/ChartVisitorDialog.vue";
export default {
  name: "PublicFooter",
  components: {
    ChartVisitorDialog,
  },
  data() {
    return {
      visitorCounter: {
        indonesia: 0,
        negaraLain: 0,
      },
    };
  },
  methods: {
    fetchVisitorCounter() {
      FVisitorService.countVisitorIndonesiaAndOtherCountry()
        .then((response) => {
          this.visitorCounter = {
            indonesia: response.data.indonesia,
            negaraLain: response.data.otherCountry,
          };
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showDialogChartVisitor() {
      this.$refs.chartVisitorDialog.dialogShow = true;
    },
  },
  mounted() {
    this.fetchVisitorCounter();
  },
};
</script>

<style scoped>
.icons {
  display: flex;
  column-gap: 10px;
}

.icon {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  border-radius: 50%;
  outline: 2px solid #fff;
  transition-property: outline-offset, outline-color, background-color;
  transition-duration: 0.25s;
}

.icon:hover {
  outline-offset: 4px;
}

.icon:hover i {
  animation: shake 0.25s;
}

.icon--instagram:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  outline-color: #a02d76;
}

.icon--twitter:hover {
  background-color: #1da1f2;
  outline-color: #1da1f2;
}

.icon-tiktok:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #9afd97 0%,
    #97fd99 5%,
    #fd5949 45%,
    #d6249f 60%,
    #35eb28 90%
  );
  outline-color: #a02d76;
}

.icon--github:hover {
  background-color: #2ea44f;
  outline-color: #2ea44f;
}

@keyframes shake {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
  }
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1b3193;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

div.font-weight-regular {
  transition: color 0.3s; /* Efek transisi warna selama 0.3 detik */
}
div.font-weight-regular:hover {
  color: red; /* Ubah warna saat mouse hover */
  cursor: pointer;
}
</style>
