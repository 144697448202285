<template>
  <nav v-if="$route.path !== '/login' && $route.path !== '/register'">
    <!-- class="color-gradient-1" -->
    <v-toolbar style="background-color: #f4f4f4" class="elevation-4">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="grey--text hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-flex class="ml-2">
        <div class="font-weight-light title">
          <h1>SILAYAK</h1>
        </div>
      </v-flex>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          plain
          elevation="0"
          color="gray darken-1"
          route
          to="/home"
          v-if="currentUser === null"
        >
          <span>Home</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down" v-if="false">
        <v-btn
          plain
          elevation="0"
          color="gray darken-1"
          route
          to="/landing-berita-agenda"
          v-if="currentUser === null"
        >
          <span>Berita-Agenda</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down" v-if="false">
        <v-btn
          plain
          elevation="0"
          color="gray darken-1"
          route
          to="/landing-informasi-teknis"
          v-if="currentUser === null"
        >
          <span>Informasi-Teknis</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              plain
              color="red darken-1"
              route
              to="/login"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </template>
          <span>Login</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      app
      style="background-color: #f4f4f4; z-index: 9999"
      class="hidden-sm-and-up"
      v-model="drawer"
      v-if="! this.$vuetify.breakpoint.mdAndUp"
    >
      <v-layout column>
        <div class="d-flex flex-row justify-center align-center mt-3 mb-2">
          <v-img
            lazy-src="../assets/logo.png"
            max-height="50"
            max-width="50"
            src="../assets/logo.png"
          ></v-img>
          <div class="ml-2 font-weight-light title">
            <h1>SILAYAK</h1>
          </div>
        </div>
      </v-layout>

      <v-divider></v-divider>
      <v-list>
        <v-list-item route to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="false" route to="/landing-berita-agenda">
          <v-list-item-icon>
            <v-icon>mdi-rss</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Berita-Agenda</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="false" route to="/landing-informasi-teknis">
          <v-list-item-icon>
            <v-icon>mdi-robot-industrial</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Informasi Teknis</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,

      menu_berita_agenda: [
        { icon: "", title: "Berita", route: "/public_berita", visible: true },
        { icon: "", title: "Agenda", route: "/public_agenda", visible: true },
      ],
      menu_informasi: [
        {
          icon: "",
          title: "Tenaga Kerja",
          route: "/public_tenaga_kerja",
          visible: true,
        },
        {
          icon: "",
          title: "Kontraktor",
          route: "/public_cv_pt",
          visible: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    signIn() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>



<style scoped>
.white {
  background-color: #f4f4f4;
}

.title h1 {
  font-size: 24px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 24px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy {
  position: relative;
  font-weight: bolder;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #0f2270;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
</style>