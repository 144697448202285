import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FTemplateRegGroupService {
    getApiUrl() {
        return API_URL
    }
    getAllFTemplateRegGroup(){
        return axios.get(API_URL + `getAllFTemplateRegGroup`, { headers: authHeader() });
    }
    getAllFTemplateRegGroupContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFTemplateRegGroup`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFTemplateRegGroupContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFTemplateRegGroupById(id){
        return axios.get(API_URL + `getFTemplateRegGroupById/${id}`, { headers: authHeader() });
    }
    updateFTemplateRegGroup(item){
        return axios.put(API_URL + `updateFTemplateRegGroup/${item.id}`, item, {headers: authHeader()})
    }
    createFTemplateRegGroup(item){
        return axios.post(API_URL + `createFTemplateRegGroup`, item, {headers: authHeader()})
    }
    deleteFTemplateRegGroup(id){
        return axios.delete(API_URL + `deleteFTemplateRegGroup/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFTemplateRegGroup(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFTemplateRegGroup`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFTemplateRegGroup`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FTemplateRegGroupService()