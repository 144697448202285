import axios from "axios";
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL;

class FJenisDokumenService {
  getApiUrl() {
    return API_URL;
  }
  getAllFJenisDokumen() {
    return axios.get(API_URL + `getAllFJenisDokumen`, {
      headers: authHeader(),
    });
  }
  getAllFJenisDokumenContaining(page, pageSize, sortBy, order, search) {
    // return axios.get(API_URL + `getAllFJenisDokumen`, { headers: authHeaderMultipart() });
    return axios.get(
      API_URL +
        `getAllFJenisDokumenContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`,
      { headers: authHeader() }
    );
  }
  getFJenisDokumenById(id) {
    return axios.get(API_URL + `getFJenisDokumenById/${id}`, {
      headers: authHeader(),
    });
  }
  updateFJenisDokumen(item) {
    return axios.put(API_URL + `updateFJenisDokumen/${item.id}`, item, {
      headers: authHeader(),
    });
  }
  createFJenisDokumen(item) {
    return axios.post(API_URL + `createFJenisDokumen`, item, {
      headers: authHeader(),
    });
  }
  deleteFJenisDokumen(id) {
    return axios.delete(API_URL + `deleteFJenisDokumen/${id}`, {
      headers: authHeader(),
    });
  }
  deleteAllFJenisDokumen(itemIds) {
    // let user = JSON.parse(localStorage.getItem('user'));
    // return axios.delete(API_URL + `deleteAllFJenisDokumen`, {
    //     headers:  authHeaderMultipart(),
    //     data: {
    //         message: 'delete  success'
    //     }
    // });
    return axios.delete(API_URL + `deleteAllFJenisDokumen`, {
      headers: authHeader(),
      data: itemIds,
    });
  }
}
export default new FJenisDokumenService();
