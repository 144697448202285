<template>
  <v-app id="app" class="grey lighten-4">
    <NavDrawer v-if="currentUser !== null" />
    <MenuBar v-if="currentUser === null" />

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
import MenuBar from "@/components/MenuBar";
import FVisitorService from "@/services/apiservices/f-visitor-service";

export default {
  components: { MenuBar: MenuBar, NavDrawer },
  name: "App",
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },
  },
  methods: {
    initIcon() {
      let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      // link.href = this.icon;
      link.href = "/assets/logo.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    isSessionActive() {
      const sessionExpiresAt = localStorage.getItem("sessionExpiresAt");
      if (!sessionExpiresAt) {
        return false; // Sesi tidak ada
      }

      // Periksa apakah waktu kedaluwarsa sesi telah tercapai
      return Date.now() < sessionExpiresAt;
    },

    addVisitor() {
      const handleLocationSuccess = (position) => {
        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=id`)
            .then((response) => response.json())
            .then((data) => {
              const fVisitor = {
                description: data.city || "Unknown Location",
                province: data.principalSubdivision || "Unknown Location",
                country: data.countryName || "Unknown Location",
              };
              console.log("Visitor data:", fVisitor);
              FVisitorService.addFVisitorCount(fVisitor).then(() => {
                console.log("Visitor added");
                updateSession();
              });
            })
            .catch((err) => {
              console.warn("Error fetching location data:", err.message);
              addUnknownVisitor();
            });
      };

      const handleLocationError = (err) => {
        console.warn("Error obtaining location:", err.message);
        addUnknownVisitor();
      };

      const updateSession = () => {
        const sessionDuration = 2 * 60 * 60 * 1000; // 2 hours
        const sessionExpiresAt = Date.now() + sessionDuration;
        localStorage.setItem("sessionExpiresAt", sessionExpiresAt.toString());
      };

      const addUnknownVisitor = () => {
        const fVisitor = {
          description: "Unknown Location",
          province: "Unknown Location",
          country: "Indonesia",
        };
        FVisitorService.addFVisitorCount(fVisitor).then(() => {
          console.log("Visitor added with unknown location");
          updateSession();
        });
      };

      if (this.isSessionActive()) {
        console.log("Session still active");
      } else {
        localStorage.removeItem("sessionExpiresAt");

        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
        } else {
          console.log("Geolocation is not supported by this browser.");
          addUnknownVisitor();
        }
      }


    }

  },
  mounted() {
    this.initIcon();
    if (!this.currentUser) {
      //Kita tidak akan pernah push apapun jika belum login
      // this.$router.push('/login');
    }
  },
  created() {
    this.initIcon();
    this.addVisitor();
  },
};
</script>

<style>
.navbar.side_drawer {
  /*color: #fad99a;*/
  color: #5d4115;
}

.color-gradient-1 {
  /*background-image: linear-gradient(to top right, #FBA80B 10%, transparent 90%);*/
  background-image: linear-gradient(
    to bottom right,
    #349323 30%,
    transparent 90%
  );
}
.color-gradient-2 {
  /*background-image: linear-gradient(to top right, #795e2b 10%, transparent 80%);*/
  background-image: linear-gradient(to top right, #6b9080 10%, transparent 85%);
  /*background-image: linear-gradient(to top right, #142a86 1%, transparent 80%);*/
}

.bottom-gradient {
  /*background-image: linear-gradient(to top right, #FBA80B 10%, transparent 90%);*/
  background-image: linear-gradient(to top right, #81461f 10%, transparent 90%);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(134, 134, 234, 0.25) 10px
  );
}

.navbar_submenu_color {
  background-color: #fff7ec;
}
</style>
