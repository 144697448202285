<template>
  <div>
    <v-card class="elevation-0">
      <v-row>
        <v-col justify="center" align="center">
          <v-card-text class="mt-3 mb-1 text-h5 font-weight-black"
            >LAYANAN</v-card-text
          >
        </v-col>
      </v-row>
      <v-layout wrap class="d-flex mt-2 justify-center">
        <div v-for="(item, i) in fTemplateRegsFiltered.filter(x => x.visibility)" :key="i">
          <div v-if="item.disabled === false">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  :to="item.group? `/registrasi-layanan-group/${item.id}`: `/registrasi-layanan/${item.id}`"
                  width="210"
                  height="250"
                  xs12
                  sm4
                  px-3
                  class="ma-3 rounded-lg pb-6"
                  :elevation="hover ? 16 : 3"
                >
                  <div>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-img
                          style="background-color: white"
                          height="165"
                          :lazy-src="lookupImageUrlLazy(item)"
                          :src="lookupImageUrl(item)"
                          :class="{ 'hover-image': hover }"
                          class="rounded-lg mx-6 mt-2 mb-1"
                        ></v-img>
                      </template>
                    </v-hover>
                  </div>
                  <v-divider class="mx-4 mt-2"></v-divider>
                  <v-card-title
                    class="text-center justify-center subtitle-2 font-weight-bold"
                    >{{ item.description }}</v-card-title
                  >
                </v-card>
              </template>
            </v-hover>
          </div>

          <div v-else-if="item.disabled === true">
            <v-card
              width="210"
              height="250"
              xs12
              sm4
              px-3
              class="ma-3 rounded-lg pb-6"
            >
              <div>
                <v-img
                  style="opacity: 0.2"
                  height="165"
                  :lazy-src="lookupImageUrlLazy(item)"
                  :src="lookupImageUrl(item)"
                  class="rounded-lg mx-6 mt-2 mb-1"
                ></v-img>
              </div>
              <v-divider class="mx-4 mt-2"></v-divider>
              <v-card-title
                class="grey--text text-center justify-center subtitle-2 font-weight-bold"
                >{{ item.description }}</v-card-title
              >
            </v-card>
          </div>
        </div>
      </v-layout>
      <v-row>
        <v-col justify="center" align="center">
          <v-card-text class="mt-16 text-h5 font-weight-black"
            >TOOLS</v-card-text
          >
        </v-col>
      </v-row>
      <v-layout wrap justify-center="true" class="d-flex mt-2">
        <div v-for="(item, i) in itemsTool" :key="i">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                @click="routeToExternalLink(item)"
                width="190"
                height="210"
                xs12
                sm4
                px-3
                class="ma-3 rounded-lg pb-6"
                :elevation="hover ? 16 : 3"
              >
                <div>
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-img
                        style="background-color: white"
                        height="145"
                        contain
                        :src="item.image"
                        :class="{ 'hover-image': hover }"
                        class="rounded-lg"
                      ></v-img>
                    </template>
                  </v-hover>
                </div>
                <v-divider class="mx-4 mt-2"></v-divider>
                <v-card-title
                  class="text-center justify-center subtitle-2 font-weight-black"
                  >{{ item.description }}</v-card-title
                >
              </v-card>
            </template>
          </v-hover>
        </div>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FJenisDokumenService from "@/services/apiservices/f-jenis-dokumen-service";
import FileService from "@/services/apiservices/file-service";
import FJenisJabatanService from "@/services/apiservices/f-jenis-jabatan-service";
import FJenisProsedurService from "@/services/apiservices/f-jenis-prosedur-service";
import FJenisKenaikanPangkatService from "@/services/apiservices/f-jenis-kenaikan-pangkat-service";
import FJenisKartuService from "@/services/apiservices/f-jenis-kartu-service";
import FTemplateRegGroupService from "@/services/apiservices/f-template-reg-group-service";

export default {
  name: "FtTemplateRegMainCardMain",
  components: {},
  data() {
    return {
      itemsTool: [
        {
          id: 1,
          image: "./assets/images/PDF.png",
          description: "PDF Convert Tools",
          url: "https://www.ilovepdf.com/id",
          to_newtab: true,
        },
        {
          id: 2,
          image: "./assets/images/TEMPLATE.png",
          description: "Download Template",
          url: "",
          to_newtab: false,
        },
      ],
      stepperPage: 1,
      title: "TEMPLATE REGISTRASI",
      snackbar: false,
      snackBarMesage: "",

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1500],
      search: "",
      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "avatarImage",
          width: "16%",
        },
        { text: "Deskripsi", value: "description", width: "40%" },
        { text: "Division", value: "fdivisionBean", width: "10%" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
      // formMode: '',
      itemSelectedIndex: -1,
      itemSelected: "",
      fTemplateRegs: [],
      fTemplateRegGroups: [],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFTemplateReg();
      },
    },
    pageSize: {
      handler: function () {
        const refreshData = this.currentPage === 1;
        this.currentPage = 1;
        if (refreshData) {
          // console.log("Change PageSize " + value)
          this.fetchFTemplateReg();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    fTemplateRegsFiltered() {
      let items = [];

      this.fTemplateRegs.forEach((item) => {
        const isDisabled = !item.statusActive;
        const isVisible = !item.ftemplateRegGroupBean > 0;

        items.push({
          ...item,
          disabled: isDisabled,
          visibility: isVisible,
          group: false,
        });
      });

      this.fTemplateRegGroups.forEach((item) => {
        const isDisabled = !item.statusActive;
        const isVisible = item.statusActive;

        items.push({
          ...item,
          disabled: isDisabled,
          visibility: isVisible,
          group: true,
        });
      });

      return items
    },

    formMode: {
      get() {
        return this.$store.state.FTemplateRegModule.formMode;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateFormMode", value);
      },
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemsFDivision", value);
      },
    },

    itemsFJenisDokumen: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisDokumen",
          value
        );
      },
    },
    itemsFJenisJabatan: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisJabatan;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisJabatan",
          value
        );
      },
    },
    itemsFJenisProsedur: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisProsedur;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisProsedur",
          value
        );
      },
    },
    itemsFJenisKenaikanPangkat: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKenaikanPangkat;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKenaikanPangkat",
          value
        );
      },
    },
    itemsFJenisKartu: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKartu;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKartu",
          value
        );
      },
    },

    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
  },
  methods: {
    nextStep(step, item) {
      this.$emit("nextStep", { step, item });
    },
    routeToExternalLink(item) {
      console.log(item);
      this.$router.push("/login");
    },
    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFTemplateReg();
      }
    },
    fetchParent() {
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
            // console.log(response.data.items)
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FJenisDokumenService.getAllFJenisDokumen().then(
          (response) => {
            this.itemsFJenisDokumen = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FJenisJabatanService.getAllFJenisJabatan().then(
          (response) => {
            this.itemsFJenisJabatan = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FJenisProsedurService.getAllFJenisProsedur().then(
          (response) => {
            this.itemsFJenisProsedur = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FJenisKenaikanPangkatService.getAllFJenisKenaikanPangkat().then(
          (response) => {
            this.itemsFJenisKenaikanPangkat = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FJenisKartuService.getAllFJenisKartu().then(
          (response) => {
            this.itemsFJenisKartu = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
        FTemplateRegService.getAllFTemplateReg().then(
          (response) => {
            this.itemsFTemplateReg = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }
    },
    fetchFTemplateReg() {
      FTemplateRegService.getAllFTemplateRegByCompany().then((response) => {
        this.fTemplateRegs = response.data;
      });
      FTemplateRegGroupService.getAllFTemplateRegGroup().then((response) => {
        this.fTemplateRegGroups = response.data;
      });
    },

    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent();
      this.fetchFTemplateReg();
    }
  },
};
</script>
  