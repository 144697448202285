export const FtRegistrasiModule = {
  namespaced: true,
  state: {
    itemModified: Object,
    itemDefault: Object,
    itemDetilModified: Object,
    itemDetilDefault: Object,

    dataSiasn: Object,

    formMode: String,
    formModeFTemplateRegDokumen: String,

    itemsFDivision: [],
    itemsFJenisDokumen: [],

    itemsFJenisJabatan: [],
    itemsFJenisProsedur: [],
    itemsFJenisKenaikanPangkat: [],
    itemsFJenisKartu: [],
    itemsUser: [],

    itemsFTemplateReg: [],
    itemFTemplateRegBean: Object,
    itemsFTemplateRegDokumen: [],

    itemsFtRegistrasiFile: [],

    isAllDescValid: false,
    isAllDocumentValid: false,
  },
  actions: {
    updateItemModified({ commit }, payload) {
      commit("mutateItemModified", payload);
    },
    updateItemDefault({ commit }, payload) {
      commit("mutateItemDefault", payload);
    },
    updateItemDetilModified({ commit }, payload) {
      commit("mutateItemDetilModified", payload);
    },
    updateItemDetilDefault({ commit }, payload) {
      commit("mutateItemDetilDefault", payload);
    },
    updateDataSiasn({ commit }, payload) {
      commit("mutateDataSiasn", payload);
    },

    updateFormMode({ commit }, payload) {
      commit("mutateFormMode", payload);
    },
    updateFormModeFTemplateRegDokumen({ commit }, payload) {
      commit("mutateFormModeFTemplateRegDokumen", payload);
    },

    updateItemsFDivision({ commit }, payload) {
      commit("mutateItemsFDivision", payload);
    },
    updateItemsFJenisDokumen({ commit }, payload) {
      commit("mutateItemsFJenisDokumen", payload);
    },

    updateItemsFJenisJabatan({ commit }, payload) {
      commit("mutateItemsFJenisJabatan", payload);
    },
    updateItemsFJenisProsedur({ commit }, payload) {
      commit("mutateItemsFJenisProsedur", payload);
    },
    updateItemsFJenisKenaikanPangkat({ commit }, payload) {
      commit("mutateItemsFJenisKenaikanPangkat", payload);
    },
    updateItemsFJenisKartu({ commit }, payload) {
      commit("mutateItemsFJenisKartu", payload);
    },
    updateItemsUser({ commit }, payload) {
      commit("mutateItemsUser", payload);
    },
    updateItemsFTemplateReg({ commit }, payload) {
      commit("mutateItemsFTemplateReg", payload);
    },
    updateItemFTemplateRegBean({ commit }, payload) {
      commit("mutateItemFTemplateRegBean", payload);
    },
    updateItemsFTemplateRegDokumen({ commit }, payload) {
      commit("mutateItemsFTemplateRegDokumen", payload);
    },

    updateItemsFtRegistrasiFile({ commit }, payload) {
      commit("mutateItemsFtRegistrasiFile", payload);
    },

    updateIsAllDescValid({ commit }, payload) {
      commit("mutateIsAllDescValid", payload);
    },
    updateIsAllDocumentValid({ commit }, payload) {
      commit("mutateIsAllDocumentValid", payload);
    },
  },

  mutations: {
    mutateItemModified(state, payLoad) {
      state.itemModified = payLoad;
    },
    mutateItemDefault(state, payLoad) {
      state.itemDefault = payLoad;
    },
    mutateItemDetilModified(state, payLoad) {
      state.itemDetilModified = payLoad;
    },
    mutateItemDetilDefault(state, payLoad) {
      state.itemDetilDefault = payLoad;
    },
    mutateDataSiasn(state, payLoad) {
      state.dataSiasn = payLoad;
    },

    mutateFormMode(state, payLoad) {
      state.formMode = payLoad;
    },
    mutateFormModeFTemplateRegDokumen(state, payLoad) {
      state.formModeFTemplateRegDokumen = payLoad;
    },
    mutateItemsFDivision(state, payLoad) {
      state.itemsFDivision = payLoad;
    },
    mutateItemsFJenisDokumen(state, payLoad) {
      state.itemsFJenisDokumen = payLoad;
    },

    mutateItemsFJenisJabatan(state, payLoad) {
      state.itemsFJenisJabatan = payLoad;
    },
    mutateItemsFJenisProsedur(state, payLoad) {
      state.itemsFJenisProsedur = payLoad;
    },
    mutateItemsFJenisKenaikanPangkat(state, payLoad) {
      state.itemsFJenisKenaikanPangkat = payLoad;
    },
    mutateItemsFJenisKartu(state, payLoad) {
      state.itemsFJenisKartu = payLoad;
    },
    mutateItemsUser(state, payLoad) {
      state.itemsUser = payLoad;
    },

    mutateItemsFTemplateReg(state, payLoad) {
      state.itemsFTemplateReg = payLoad;
    },
    mutateItemFTemplateRegBean(state, payLoad) {
      state.itemFTemplateRegBean = payLoad;
    },
    mutateItemsFTemplateRegDokumen(state, payLoad) {
      state.itemsFTemplateRegDokumen = payLoad;
    },
    mutateItemsFtRegistrasiFile(state, payLoad) {
      state.itemsFtRegistrasiFile = payLoad;
    },

    mutateIsAllDescValid(state, payLoad) {
      state.isAllDescValid = payLoad;
    },
    mutateIsAllDocumentValid(state, payLoad) {
      state.isAllDocumentValid = payLoad;
    },
  },
};
