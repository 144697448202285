var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('v-card-text',{staticClass:"mt-3 mb-1 text-h5 font-weight-black"},[_vm._v("LAYANAN")])],1)],1),_c('v-layout',{staticClass:"d-flex mt-2 justify-center",attrs:{"wrap":""}},_vm._l((_vm.fTemplateRegsFiltered.filter(function (x) { return x.visibility; })),function(item,i){return _c('div',{key:i},[(item.disabled === false)?_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-3 rounded-lg pb-6",attrs:{"to":item.group? ("/registrasi-layanan-group/" + (item.id)): ("/registrasi-layanan/" + (item.id)),"width":"210","height":"250","xs12":"","sm4":"","px-3":"","elevation":hover ? 16 : 3}},[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"rounded-lg mx-6 mt-2 mb-1",class:{ 'hover-image': hover },staticStyle:{"background-color":"white"},attrs:{"height":"165","lazy-src":_vm.lookupImageUrlLazy(item),"src":_vm.lookupImageUrl(item)}})]}}],null,true)})],1),_c('v-divider',{staticClass:"mx-4 mt-2"}),_c('v-card-title',{staticClass:"text-center justify-center subtitle-2 font-weight-bold"},[_vm._v(_vm._s(item.description))])],1)]}}],null,true)})],1):(item.disabled === true)?_c('div',[_c('v-card',{staticClass:"ma-3 rounded-lg pb-6",attrs:{"width":"210","height":"250","xs12":"","sm4":"","px-3":""}},[_c('div',[_c('v-img',{staticClass:"rounded-lg mx-6 mt-2 mb-1",staticStyle:{"opacity":"0.2"},attrs:{"height":"165","lazy-src":_vm.lookupImageUrlLazy(item),"src":_vm.lookupImageUrl(item)}})],1),_c('v-divider',{staticClass:"mx-4 mt-2"}),_c('v-card-title',{staticClass:"grey--text text-center justify-center subtitle-2 font-weight-bold"},[_vm._v(_vm._s(item.description))])],1)],1):_vm._e()])}),0),_c('v-row',[_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('v-card-text',{staticClass:"mt-16 text-h5 font-weight-black"},[_vm._v("TOOLS")])],1)],1),_c('v-layout',{staticClass:"d-flex mt-2",attrs:{"wrap":"","justify-center":"true"}},_vm._l((_vm.itemsTool),function(item,i){return _c('div',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-3 rounded-lg pb-6",attrs:{"width":"190","height":"210","xs12":"","sm4":"","px-3":"","elevation":hover ? 16 : 3},on:{"click":function($event){return _vm.routeToExternalLink(item)}}},[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"rounded-lg",class:{ 'hover-image': hover },staticStyle:{"background-color":"white"},attrs:{"height":"145","contain":"","src":item.image}})]}}],null,true)})],1),_c('v-divider',{staticClass:"mx-4 mt-2"}),_c('v-card-title',{staticClass:"text-center justify-center subtitle-2 font-weight-black"},[_vm._v(_vm._s(item.description))])],1)]}}],null,true)})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }