<template>
  <div class="ma-4">
    <v-row>
      <v-col>
        <span class="font-weight-black subtitle-1">DASHBOARD</span>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="12" sm="5" md="5" v-if="false">
        <v-hover v-slot="{hover }" open-delay="200">
          <v-card  class="top-gradient-teal" :elevation="hover ? 16 : 2">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4 font-weight-bold">Registrasi</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="100"
                          >
                            {{  10200 }}
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-list-item class="mt-6">
                  <v-list-item-content>
                    <v-list-item-subtitle class="gray--text">Jumlah RTLH: 11111</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text ">Pagu 20222: 22222</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>


            </v-row>
          </v-card>
        </v-hover>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-hover v-slot="{hover }" open-delay="200">
          <v-card  class="top-gradient-teal" :elevation="hover ? 16 : 2">
            <v-row>

              <v-col cols="12" sm="2" md="2">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Registrasi</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="60"
                          >
                            {{  60 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Tervalidasi</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="60"
                          >
                            {{  60 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Disetujui</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="60"
                          >
                            {{  60 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="2" md="">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Komplit</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="60"
                          >
                            {{  60 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- <v-list-item-subtitle class="gray&#45;&#45;text">Jumlah RTLH: 11111</v-list-item-subtitle>-->
                    <!-- <v-list-item-subtitle class="gray&#45;&#45;text ">Jumlah Dana Bantuan Tahun Ini: 22222</v-list-item-subtitle>-->
                  </v-list-item-content>
                </v-list-item>
              </v-col>

            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Dashboard"
}
</script>

<style scoped>

</style>