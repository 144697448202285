const ConstApiUrls = Object.freeze({
  // AUTH_SERVICE_URL: 'http://localhost:9193/api/auth/',
  // TEST_SERVICE_URL: 'http://localhost:9193/api/test/',
  // API_SERVICE_URL: 'http://localhost:9193/api/silayak/'

  AUTH_SERVICE_URL: "https://rest-silayak.des-green.com/api/auth/",
  TEST_SERVICE_URL: "https://rest-silayak.des-green.com/api/test/",
  API_SERVICE_URL: "https://rest-silayak.des-green.com/api/silayak/",

  // AUTH_SERVICE_URL: "https://layak-backend.palangkaraya.go.id/api/auth/",
  // TEST_SERVICE_URL: "https://layak-backend.palangkaraya.go.id/api/test/",
  // API_SERVICE_URL: "https://layak-backend.palangkaraya.go.id/api/silayak/",

});
export default ConstApiUrls;
