export default class FtRegistrasi {
  constructor(
    id = 0,
    kode1 = "", //sudah
    kode2 = "",
    description = "", //sudah
    avatarImage = "", //sudah
    statusActive = true, //sudah
    fdivisionBean = 0, //sudah
    nip = "", //sudah
    periodeYear = 0, //sudah
    periodeMonth = 0, //sudah
    ftemplateRegBean = 0, //otomatis ketika pilih jenis reg
    trDate = new Date(),
    fjenisProsedurBean = 0, //sudah
    fjenisKenaikanPangkatBean = 0, //sudah
    fjenisJabatanBean = 0, //sudah
    fjenisKartuBean = 0, //sudah
    submitted = false,
    validasiStatus = 0,
    validasiReasonCode = 0,
    verifikatorBean = 0,
    validasiReasons = "",
    approvedStatus = 0,
    approvedReasons = "",
    toNumber = "",
    created = new Date(),
    modified = new Date(),
    modifiedBy = ""
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.nip = nip;
    this.periodeYear = periodeYear;
    this.periodeMonth = periodeMonth;
    this.ftemplateRegBean = ftemplateRegBean;
    this.trDate = trDate;
    this.fjenisProsedurBean = fjenisProsedurBean;
    this.fjenisKenaikanPangkatBean = fjenisKenaikanPangkatBean;
    this.fjenisJabatanBean = fjenisJabatanBean;
    this.fjenisKartuBean = fjenisKartuBean;
    this.submitted = submitted;
    this.validasiStatus = validasiStatus;
    this.validasiReasons = validasiReasons;
    this.validasiReasonCode = validasiReasonCode;
    this.verifikatorBean = verifikatorBean;
    this.approvedStatus = approvedStatus;
    this.approvedReasons = approvedReasons;
    this.toNumber = toNumber;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
