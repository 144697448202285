import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VeeValidate  from 'vee-validate'
Vue.use(VeeValidate);
// import { ValidationProvider } from 'vee-validate';
// Vue.component('ValidationProvider', ValidationProvider);


import VNumeric from 'vuetify-numeric/vuetify-numeric.umd'
Vue.use(VNumeric)

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import VueLayers from 'vuelayers'
// register all VueLayers components
Vue.use(VueLayers, {
  // global data projection, see https://vuelayers.github.io/#/quickstart?id=global-data-projection
  // dataProjection: 'EPSG:4326',
})



import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps'
Vue.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAEvDawNR_yp8SGdQsP8zTfB9OK39XFnBU',
    libraries: ['places'],
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
