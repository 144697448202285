import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FJenisProsedurService {
    getApiUrl() {
        return API_URL
    }
    getAllFJenisProsedur(){
        return axios.get(API_URL + `getAllFJenisProsedur`, { headers: authHeader() });
    }
    getAllFJenisProsedurContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFJenisProsedur`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFJenisProsedurContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFJenisProsedurById(id){
        return axios.get(API_URL + `getFJenisProsedurById/${id}`, { headers: authHeader() });
    }
    updateFJenisProsedur(item){
        return axios.put(API_URL + `updateFJenisProsedur/${item.id}`, item, {headers: authHeader()})
    }
    createFJenisProsedur(item){
        return axios.post(API_URL + `createFJenisProsedur`, item, {headers: authHeader()})
    }
    deleteFJenisProsedur(id){
        return axios.delete(API_URL + `deleteFJenisProsedur/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFJenisProsedur(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFJenisProsedur`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFJenisProsedur`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FJenisProsedurService()